import React from "react"
import Layout from "../components/layout"

const Store = () => {
  return (
    <Layout>
      <p>Coming soon</p>
    </Layout>
  )
}

export default Store
